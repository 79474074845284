<template>
  <div class="footer">
    <ul class="footer-top">
      <li>
        <img src="@/assets/images/logo-title.png" alt="" width="144px" />
        <div class="footer-top_qrcode">
          <img src="@/assets/images/qrcode.png" alt="" width="100%"/>
        </div>
      </li>
      <li>
        <h6>产品与解决方案</h6>
        <router-link to="/quality">AI智能病历质控</router-link>
        <router-link to="/diagnosis">AI智能辅助诊疗</router-link>
      </li>
      <li>
        <h6>精选案例</h6>
        <router-link to="/stories">邵逸夫医院</router-link>
        <router-link to="/stories">浙江省人民医院</router-link>
      </li>
      <li>
        <h6>关于我们</h6>
        <router-link to="/aboutUs">公司简介</router-link to="">
      </li>
      <li>
        <h6>北京如玥星辰科技有限公司</h6>
        <p>地址：北京市朝阳区联合大厦1604</p>
        <p>电话：18210566607</p>
        <p style="padding-left: 36px">13811973070</p>
      </li>
    </ul>
    <div class="footer-bottom">
      <p>
        <a href="https://beian.miit.gov.cn">©2023 北京如玥星辰科技有限公司 京ICP备2023019192号-1</a>
        <!-- <span>用户协议 | 服务条款 | 隐私政策</span> -->
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  height: 330px;
  background: #f5f9ff;
  &-top {
    width: 1200px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    display: flex;
    padding-top: 58px;
    padding-bottom: 44px;
    margin-bottom: 32px;
    h6 {
      font-size: 14px;
      margin-bottom: 32px;
      margin-top: 0;
    }
    a:hover {
      text-decoration: underline;
    }
    a,
    p {
      display: block;
      font-size: 12px;
      color: #666666;
      margin-bottom: 8px;
      margin-top: 0;
    }
    &_qrcode {
      width: 100px;
      height: 100px;
      margin-left: 44px;
      margin-top: 18px;
    }
  }
  &-bottom {
    width: 1200px;
    margin: 0 auto;
    a,
    p {
      color: #666666;
      font-size: 12px;
      text-align: center;
      span {
        float: right;
      }
    }
  }
}
</style>